import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";

import { getMetaText } from "../../../AppText";
import { RootState } from "../../../state/rootReducer";
import { setCollective } from "../../../state/slices/userSlice";
import { GET_CUSTOMER } from "../../../graphql/User";
import { CASKS, Cask } from "../../../graphql/Casks";
import styles from "./WelcomeToCaskshare.module.scss";
import LoadingLogo from "../../../components/app/LoadingLogo";
import TagManager from "react-gtm-module";
import FeaturedBottlesComp from "../../../caskshare-v3/components/app/FeaturedBottlesComp";

const WelcomeToCaskshare = ({ unauthenticated }: {unauthenticated?: boolean}) => {
  const [user, setUser] = useState<any>({});
  const [cask, setCask] = useState<Cask[]>([]);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const shop = useSelector((state: RootState) => state.shop);

  const { data, refetch } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "network-only",
    variables: {
      shop: shop.id,
      awaitRefetchQuires: true,
    },
  });

  const { data: caskData, loading: casksLoading } = useQuery(CASKS, {
    variables: {
      shop: shop.id,
    },
    skip: !shop.id,
  });

  useEffect(() => {
    if (!data) {
      return;
    } else {
      setUser(data.customer);
    }
  }, [data]);

  const asyncTimeout = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const reRun = async () => {
    await refetch();
    await asyncTimeout(2000);
    count < 5 && setCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (data?.customer?.collectiveActive === false && count < 5) {
      reRun();
    } else if (data?.customer?.collectiveActive === true) {
      dispatch(setCollective(data.customer.collectiveActive));

      // Google Analytics 4
      TagManager.dataLayer({ dataLayer: { ecommerce: null } });
      var tagManagerArgs = {
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: data?.customer?.collective?.id,
            value: data?.customer?.collective?.value,
            price: data?.customer?.collective?.value,
            currency: shop.currency.toUpperCase(),
            commission_group: "CSMEMBER",
            discount_code: data?.customer?.collective?.promotionCode,
          },
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [data?.customer, data?.customer?.collectiveActive, count]);

  useEffect(() => {
    if (caskData?.casks) {
      const shippingNow: Cask[] = [
        ...caskData.casks.filter(
          (cask: Cask) => cask.shippingTimeframe === "shipping_now",
        ),
      ];
      setCask(shippingNow.length ? shippingNow : caskData.casks);
    }
  }, [caskData?.casks]);

  const marketString = (market: string) => {
    switch (market) {
      case "GB":
        return "UK";
      case "US":
        return "US";
      case "EU":
        return "EU";
      default:
        return "";
    }
  };

  if (!cask) {
    return <LoadingLogo />;
  }

  if (!unauthenticated && !user) {
    return <LoadingLogo />;
  }

  return (
    <>
      <Helmet>
        <title>{getMetaText("REGISTER_PAGE", "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="keywords"
          content={getMetaText("REGISTER_PAGE", "keywords")}
        />
        <meta
          name="description"
          content={getMetaText("REGISTER_PAGE", "description")}
        />
        <meta name="author" content={getMetaText("REGISTER_PAGE", "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={getMetaText("REGISTER_PAGE", "title")}
        />
        <meta
          property="og:description"
          content={getMetaText("REGISTER_PAGE", "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("REGISTER_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("REGISTER_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/home-page/splash.jpg")
          }
        />
      </Helmet>
      <Container fluid className={styles.welcomeToCaskshareWrap}>
        <Container className={styles.topWrap}>
          <Row className={styles.welcomeWrap}>
            <Col xs={12} lg={6} className={styles.contentWrap}>
              <h4>
                Thank you for joining{" "}
                {user.collectiveActive || unauthenticated
                  ? `The Caskshare Collective ${marketString(shop.market)}`
                  : `Caskshare ${marketString(shop.market)}`}
                !
              </h4>
              <p>
                The Uncompromised Experience of single cask whisky, by the
                bottle, and the world's finest distilleries at your fingertips.
              </p>
              <div className={styles.buttonWrap}>
                {user.collectiveActive || unauthenticated ? (
                  <Button
                    as={Link}
                    to="/my-account/collective"
                    className={styles.btnCoralOutline}
                  >
                    My Collective
                  </Button>
                ) : (
                  <Button
                    as={Link}
                    to="/the-collective"
                    className={styles.btnCoralOutline}
                  >
                    Join the Collective
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={12} lg={6} className={styles.imgWrap}></Col>
          </Row>
        </Container>
        {cask.length ? (
          <div className={styles.featuredBG}>
            <FeaturedBottlesComp
              caskData={cask}
              user={user}
              casksLoading={casksLoading}
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default WelcomeToCaskshare;

import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardElement,
  PaymentRequest,
  PaymentRequestCompleteStatus,
} from "@stripe/stripe-js";
import { useHistory, Redirect } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { RootState } from "../../../../state/rootReducer";
import {
  CHECKOUT_COMPLETE,
  CHECKOUT_INTENT,
  CHECKOUT_FINALISE,
  GET_CHECKOUT,
  CHECKOUT_PAYMENT_FAILED,
} from "../../../../graphql/Checkout";
import {
  GET_CUSTOMER,
  GET_GUEST,
  ADDRESS_UPSERT,
} from "../../../../graphql/User";
import { LineItem } from "../../../../graphql/LineItem";

import PaymentFailureModal from "../../PaymentFailureModal";
import { Countries, UsStates } from "../../../CountryData";
import { formatCurrency } from "../../../../utils/currency";

import { store } from "../../../../state/store";

import {
  addAddressToCheckout,
  addAddressesToCheckout,
  addValidationCounter,
  sanitizeLineItems,
  sanitizeAddress,
  setCheckoutId,
  setStockIssues,
} from "../../../../state/slices/checkoutSlice";

import { getCopyText } from "../../../../AppText";

import AddressForm from "../../../account/AddressForm";
import styles from "./Payment.module.scss";

import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

import { MdWarning } from "react-icons/md";
import PaymentMollie from "./PaymentMollie";
import LoadingView from "../../../app/LoadingView";
import LoadingLogo from "../../../app/LoadingLogo";

const SHOW_TEST_CARD_NUMBER = false;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const Payment = ({
  isOversold,
  setIsOversold,
  isFinalPaymentRedirect = false,
}: {
  isOversold: boolean;
  setIsOversold?: any;
  isFinalPaymentRedirect?: boolean;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [validationError, setValidationError] = useState<any>(null);
  const [paymentError, setPaymentError] = useState<any>(null);
  const [addressError, setAddressError] = useState<any>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sameBillingAndShipping, setSameBillingAndShipping] = useState(true);
  const [isShippingValidated, setIsShippingValidated] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [shippingMessage, setShippingMessage] = useState("");
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const [shippingAddress, setShippingAddress] = useState<any>({});
  const [billingAddress, setBillingAddress] = useState<any>({});
  const [shippingAddresses, setShippingAddresses] = useState<any>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [shippingAddressVisible, setShippingAddressVisible] = useState(true);
  const [paymentFormVisible, setPaymentFormVisible] = useState(true);
  const [billingAddressVisible, setBillingAddressVisible] = useState(true);
  const [addressFormVisible, setAddressFormVisible] = useState(false);
  const [shippingAddressesVisible, setShippingAddressesVisible] =
    useState(false);
  const [activeItem, setActiveItem] = useState({
    status: 0,
    id: "",
  });
  const [accountDetails, setAccountDetails] = useState<any>({
    // customer fields - from customer
    firstName: "",
    lastName: "",
    email: "",
    // address fields - from customer.addresses[0]
    id: "",
    address1: "",
    address2: "",
    phone: "",
    city: "",
    stateCode: "",
    countryCode: "",
    postalCode: "",
    deliveryInstruction: "",
  });
  const [guestEmailForQuery, setGuestEmailForQuery] = useState("");
  const [isFinalPaymentStatusConfirmed, setIsFinalPaymentStatusConfirmed] =
    useState(false);

  const user = useSelector((state: RootState) => state.user);
  const checkout = useSelector((state: RootState) => state.checkout);
  const shopId = useSelector((state: RootState) => state.shop.id);

  const dispatch = useDispatch();

  const shop = useSelector((state: RootState) => state.shop);
  const [checkoutFinalise] = useMutation(CHECKOUT_FINALISE);
  const [checkoutPaymentIntent] = useMutation(CHECKOUT_INTENT);
  const [checkoutComplete] = useMutation(CHECKOUT_COMPLETE);
  // const [checkoutPaymentFailed] = useMutation(CHECKOUT_PAYMENT_FAILED);
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      shop: shop.id,
    },
    fetchPolicy: "network-only",
  });

  const { data: guestData, loading: guestLoading } = useQuery(GET_GUEST, {
    variables: {
      shop: shop.id,
      email: guestEmailForQuery,
    },
  });

  const [addressUpsert] = useMutation(ADDRESS_UPSERT, {
    variables: {
      shop: shopId,
      email: accountDetails.email,
      input: accountDetails,
      category: "shipping",
    },
    refetchQueries: [
      {
        query: user?.email ? GET_CUSTOMER : GET_GUEST,
        variables: { shop: shopId, email: accountDetails.email },
      },
    ],
    awaitRefetchQueries: true,
  });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const doCheckout = async () => {
    const { data: checkoutData } = await checkoutFinalise({
      variables: {
        shop: shop.id,
        input: {
          id: store.getState().checkout.id,
          email: user?.email ? user?.email : shippingAddress.email,
          currency: shop.currency,
          lineItems: sanitizeLineItems(store.getState().checkout.lineItems),
          shippingLine: store.getState().checkout.shippingLine,
          shippingAddress: sanitizeAddress(
            user.email
              ? store.getState().checkout.shippingAddress
              : shippingAddress,
          ),
          billingAddress: sameBillingAndShipping
            ? sanitizeAddress(
                user.email
                  ? store.getState().checkout.shippingAddress
                  : shippingAddress,
              )
            : sanitizeAddress(
                user.email
                  ? store.getState().checkout.billingAddress
                  : billingAddress,
              ),
          redeemedFunds: store.getState().checkout.redeemedFunds,
          amountTotal: store.getState().checkout.amountTotal,
          amountPayable: store.getState().checkout.amountPayable,
          promoCode: store.getState().checkout.promoCode?.code,
        },
      },
    });

    if (checkoutData.checkoutFinalise.error) {
      setPaymentError(checkoutData.checkoutFinalise.error || "");
      setIsProcessing(false);
      return;
    } else if (checkoutData.checkoutFinalise.stockIssues?.length > 0) {
      dispatch(setStockIssues(checkoutData.checkoutFinalise.stockIssues));
      console.log("redirecting to basket page...");
      return (
        <Redirect
          to={{
            pathname: "/checkout/payment",
          }}
        />
      );
    } else if (checkoutData.checkoutFinalise.insufficientBalance === true) {
      setPaymentError("Insufficient balance, please top-up and try again.");
      return;
    } else {
      dispatch(setCheckoutId(checkoutData.checkoutFinalise.checkout.id));
    }

    if (checkoutData.checkoutFinalise.paymentPending === true) {
      const { data } = await checkoutPaymentIntent({
        variables: {
          shop: shop.id,
          id: checkoutData.checkoutFinalise.checkout.id,
          currency: shop.currency,
          email: user?.email ? user?.email : shippingAddress.email,
          firstName: billingAddress.firstName || shippingAddress.firstName,
          lastName: billingAddress.lastName || shippingAddress.lastName,
          cardToken: mollieCardToken || undefined,
        },
      });

      // Mollie
      if (data?.checkoutPaymentIntent.scaRedirectUrl) {
        window.location.replace(data.checkoutPaymentIntent.scaRedirectUrl);
        return;
      }
      // End Mollie redirect

      return data?.checkoutPaymentIntent.paymentIntent.clientSecret;
    }
  };

  const [mollieCardToken, setMollieCardToken] = useState<String | null>(null);
  const [checkoutPollingStartedAt, setCheckoutPollingStartedAt] =
    useState<Date | null>(null);
  const checkoutPollingLimit_Seconds = 5;

  const [checkoutPaymentFailure] = useMutation(CHECKOUT_PAYMENT_FAILED, {
    onCompleted: (data) => {
      setIsFinalPaymentStatusConfirmed(true);
    },
  });

  const [getCheckout] = useLazyQuery(GET_CHECKOUT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.checkout?.status === "completed") {
        handleAnalyticsAndSendUserToSuccessPage();
        return;
      }
      if (
        checkoutPollingStartedAt &&
        (new Date().getTime() - checkoutPollingStartedAt.getTime()) / 1000 <
          checkoutPollingLimit_Seconds
      ) {
        getCheckout({
          variables: {
            shop: shop.id,
            id: store.getState().checkout.id,
          },
        });
      } else {
        console.error("Checkout polling timed-out");
        checkoutPaymentFailure({
          variables: {
            shop: shop.id,
            id: store.getState().checkout.id,
          },
        });
        setPaymentError("Failed to complete checkout");
        setIsFinalPaymentStatusConfirmed(true);
      }
    },
    onError: (error) => {
      console.error(error.message);
      setPaymentError("Failed to complete checkout");
      setIsFinalPaymentStatusConfirmed(true);
    },
  });

  async function submitMollieCardTokenToBackend() {
    console.log("Submitting mollieCardToken to Caskshare back-end");
    await doCheckout();
  }

  useEffect(() => {
    if (mollieCardToken && mollieCardToken.length > 0) {
      submitMollieCardTokenToBackend().catch(console.error);
    }
  }, [mollieCardToken]);

  useEffect(() => {
    if (isFinalPaymentRedirect) {
      pollForPaymentConfirmation();
    }
  }, []);

  const pollForPaymentConfirmation = async () => {
    setTimeout(() => {
      finalizeCheckout().catch(console.error);
    }, 2000);
  };

  const finalizeCheckout = async () => {
    setCheckoutPollingStartedAt(new Date());
    getCheckout({
      variables: {
        shop: shop.id,
        id: store.getState().checkout.id,
      },
    });
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: shop.market == "US" ? "US" : "GB",
        currency: shop.currency ?? "gbp",
        total: {
          label: "Total",
          amount: +(store.getState().checkout.amountPayable * 100).toFixed(2),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        } else {
        }
      });

      pr.on("paymentmethod", async (ev) => {
        const [firstName, ...lastNames] = ev.payerName?.split(" ") || [];
        if (!sameBillingAndShipping) {
          setBillingAddress({
            ...billingAddress,
            firstName: firstName,
            lastName: lastNames.join(" "),
          });
        }

        const clientSecret = await doCheckout();

        const status: PaymentRequestCompleteStatus = await completeCheckout(
          clientSecret,
          ev.paymentMethod.id,
        );
        ev.complete(status);
      });
    }
  }, [stripe]);

  useEffect(() => {
    let isSubscribed = true;
    if (!data || !data.customer) {
      return;
    } else if (data.customer && isSubscribed) {
      setShippingAddress(data.customer.shippingAddress);
      setShippingAddresses(data.customer.shippingAddresses);
      if (sameBillingAndShipping === false) {
        setBillingAddress(data.customer.billingAddress);
      }
      setAccountDetails({ ...accountDetails, email: data.customer.email });
    }
    return () => {
      isSubscribed = false;
    };
  }, [data, data?.customer]);

  useEffect(() => {
    if (!user?.email) {
      let obj: any = sessionStorage.getItem("shippingAddress");
      obj && setShippingAddress(JSON.parse(obj));
    }
  }, [loading, guestLoading]);

  useEffect(() => {
    if (!user?.email && shippingAddress?.email) {
      setGuestEmailForQuery(shippingAddress.email);
    }
  }, [shippingAddress?.email]);

  useEffect(() => {
    if (sameBillingAndShipping) {
      setBillingAddress({});
    } else {
      setBillingAddress(shippingAddress);
    }
  }, [sameBillingAndShipping]);

  useEffect(() => {
    let isSubscribed = true;
    if (!isShippingValidated && shippingAddress.firstName) {
      setShippingMessage(
        "Unfortunately, we are unable to ship to your country at present.",
      );
    } else if (isShippingValidated && isSubscribed) {
      setShippingMessage("");
    }
    return () => {
      isSubscribed = false;
    };
  }, [isShippingValidated, shippingAddress?.firstName]);

  useEffect(() => {
    if (shippingAddress?.countryCode !== "US") {
      setShippingAddress({ ...shippingAddress, stateCode: "" });
    }
  }, [shippingAddress?.countryCode]);

  useEffect(() => {
    if (shippingAddress.postalCode && !isFinalPaymentRedirect) {
      // console.log(
      //   `dispatch addAddressesToCheckout ${sameBillingAndShipping} ${shippingAddress.postalCode} ${billingAddress.postalCode}`,
      // );
      if (sameBillingAndShipping) {
        dispatch(addAddressesToCheckout(shippingAddress, shippingAddress));
      } else {
        dispatch(addAddressesToCheckout(shippingAddress, billingAddress));
      }
    } else {
      return;
    }
  }, [shippingAddress?.postalCode]);

  useEffect(() => {
    if (
      store.getState().checkout.shippingLine.id &&
      shippingAddress?.postalCode
    ) {
      setIsShippingValidated(true);
      setValidationLoading(false);
    }
    return () => {
      setIsShippingValidated(false);
      setValidationLoading(true);
    };
  }, [
    store.getState().checkout.validationCounter,
    shippingAddress?.postalCode,
    shippingAddress?.stateCode,
  ]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: "Total",
          amount: +(store.getState().checkout.amountPayable * 100).toFixed(2),
        },
      });
    }
  }, [store.getState().checkout.amountPayable]);

  if (!store.getState().checkout) {
    return <h5>Empty Checkout</h5>;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const completeCheckout = async (
    clientSecret: string,
    paymentMethod?: string,
  ): Promise<PaymentRequestCompleteStatus> => {
    if (!stripe) {
      return "fail";
    }

    const payment = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod,
    });

    if (payment.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(payment.error.message);
      setPaymentError(payment.error.message);
      return "fail";
    } else {
      // The payment has been processed!
      if (payment?.paymentIntent?.status === "succeeded") {
        // Show a success message to your customer
        const { data } = await checkoutComplete({
          variables: {
            shop: shop.id,
            id: store.getState().checkout.id,
            email: user?.email ? user?.email : shippingAddress.email,
            tokenizedPayment: {
              paymentAmount: store.getState().checkout.amountPayable,
              paymentIntent: payment?.paymentIntent?.id,
            },
          },
        });

        if (!data) {
          setPaymentError("Failed to complete checkout");
          return "fail";
        }

        pollForPaymentConfirmation();
      }
    }
    setIsProcessing(false);
    return "success";
  };

  const handleAnalyticsAndSendUserToSuccessPage = () => {
    // Google Analytics - Universal
    ReactGA.plugin.execute("ecommerce", "addTransaction", {
      id: store.getState().checkout.id,
      revenue: store.getState().checkout.amountTotal,
      currency: shop.currency.toUpperCase(),
    });

    store.getState().checkout.lineItems.map((item: LineItem) =>
      ReactGA.plugin.execute("ecommerce", "addItem", {
        id: item.id,
        name: item.title,
        sku: item.sku,
        category: item.type,
        price: item.publicTotalPrice,
        quantity: item.quantity,
        currency: shop.currency.toUpperCase(),
      }),
    );

    ReactGA.plugin.execute("ecommerce", "send", null);
    ReactGA.plugin.execute("ecommerce", "clear", null);

    // Facebook - only if permission has been granted via Cookie consent
    ReactPixel.track("Purchase", {
      value: store.getState().checkout.amountTotal,
      currency: shop.currency.toUpperCase(),
      contents: store.getState().checkout.lineItems.map((item: LineItem) => ({
        id: item.id,
        quantity: item.quantity,
        name: item.title,
      })),
    });

    // Google Analytics 4
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });

    // if any lineitems have title collective then set commission_group to CSMEMBER
    let commission_group = 'CSPRODUCT'
    if (store.getState().checkout.lineItems.some((item: LineItem) => item.title?.includes('Collective'))) {
      commission_group = 'CSMEMBER';
    }

    var tagManagerArgs = {
      dataLayer: {
        event: "purchase",
        ecommerce: {
          transaction_id: store.getState().checkout.id,
          value: store.getState().checkout.amountTotal,
          currency: shop.currency.toUpperCase(),
          shipping: store.getState().checkout.shippingLine.priceTotal,
          promo_code: store.getState().checkout.promoCode?.code,
          commission_group: commission_group,
          items: store.getState().checkout.lineItems.map((item: LineItem) => {
            return {
              item_id: item.sku,
              item_brand: item.vendor,
              item_name: item.title,
              price: item.publicUnitPrice,
              quantity: item.quantity,
              item_category: item.type,
              promo_code: item.promoCode,
            }
          }),
        },
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    history.push("/checkout-success");
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleCardDetailsChange = (ev: any) => {
    ev.error ? setValidationError(ev.error.message) : setValidationError("");
  };
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const asyncTimeout = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsProcessing(true);
    await asyncTimeout(300);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setIsProcessing(false);
      return;
    }

    const billingName =
      sameBillingAndShipping === true
        ? `${shippingAddress.firstName} ${shippingAddress.lastName}`
        : `${billingAddress.firstName} ${billingAddress.lastName}`;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement) as StripeCardElement,
      billing_details: {
        name: billingName,
        email: user?.email ? user?.email : shippingAddress.email,
        address: {
          line1:
            sameBillingAndShipping === true
              ? shippingAddress.address1
              : billingAddress.address1,
          line2:
            sameBillingAndShipping === true
              ? shippingAddress.address2
              : billingAddress.address2,
          city:
            sameBillingAndShipping === true
              ? shippingAddress.city
              : billingAddress.city,
          state:
            sameBillingAndShipping === true
              ? shippingAddress.stateCode
              : billingAddress.stateCode,
          country:
            sameBillingAndShipping === true
              ? shippingAddress.countryCode
              : billingAddress.countryCode,
        },
      },
    });

    if (error) {
      console.log("[error]", error);
      setPaymentError(error.message || error.code || "");
      setIsProcessing(false);
      return;
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }

    const clientSecret = await doCheckout();

    await completeCheckout(clientSecret, paymentMethod?.id);
  };

  const doZeroCheckout = async (e: any) => {
    setIsProcessing(true);
    e.preventDefault();
    await asyncTimeout(300);

    const { data: checkoutData } = await checkoutFinalise({
      variables: {
        shop: shop.id,
        input: {
          id: store.getState().checkout.id,
          email: user?.email ? user?.email : shippingAddress.email,
          currency: shop.currency,
          lineItems: sanitizeLineItems(store.getState().checkout.lineItems),
          shippingLine: store.getState().checkout.shippingLine,
          shippingAddress: sanitizeAddress(
            store.getState().checkout.shippingAddress,
          ),
          redeemedFunds: store.getState().checkout.redeemedFunds,
          amountTotal: store.getState().checkout.amountTotal,
          amountPayable: store.getState().checkout.amountPayable,
          promoCode: store.getState().checkout.promoCode?.code,
        },
      },
    });

    if (checkoutData.checkoutFinalise.insufficientBalance === true) {
      setPaymentError("Insufficient balance, please top-up and try again.");
      setIsProcessing(false);
      return;
    }

    if (checkoutData.checkoutFinalise.error) {
      setPaymentError(checkoutData.checkoutFinalise.error || "");
      setIsProcessing(false);
      return;
    } else {
      dispatch(setCheckoutId(checkoutData.checkoutFinalise.checkout.id));
    }

    // Google Analytics - Universal
    ReactGA.plugin.execute("ecommerce", "addTransaction", {
      id: store.getState().checkout.id,
      revenue: store.getState().checkout.amountTotal,
      currency: shop.currency,
    });

    store.getState().checkout.lineItems.map((item: LineItem) =>
      ReactGA.plugin.execute("ecommerce", "addItem", {
        id: item.id,
        name: item.title,
        sku: item.sku,
        category: item.type,
        price: item.publicTotalPrice,
        quantity: item.quantity,
        currency: shop.currency,
      }),
    );

    ReactGA.plugin.execute("ecommerce", "send", null);
    ReactGA.plugin.execute("ecommerce", "clear", null);

    // Facebook - only if permission has been granted via Cookie consent
    ReactPixel.track("Purchase", {
      value: store.getState().checkout.amountTotal,
      currency: shop.currency,
      contents: store.getState().checkout.lineItems.map((item: LineItem) => ({
        id: item.id,
        quantity: item.quantity,
        name: item.title,
      })),
    });

    // Google Analytics 4
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    var tagManagerArgs = {
      dataLayer: {
        event: "purchase",
        ecommerce: {
          transaction_id: store.getState().checkout.id,
          value: store.getState().checkout.amountTotal,
          currency: shop.currency.toUpperCase(),
          shipping: store.getState().checkout.shippingLine.priceTotal,
          items: store.getState().checkout.lineItems.map((item: LineItem) => {
            let commission_group = 'CSPRODUCT'
            if (item.title.includes('Collective')) {
              commission_group = 'CSMEMBER';
            }

            return {
              item_id: item.sku,
              item_brand: item.vendor,
              item_name: item.title,
              price: item.publicTotalPrice,
              quantity: item.quantity,
              item_category: item.type,
              promo_code: item.promoCode,
              commission_group: commission_group,
            }
          }),
        },
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setIsProcessing(false);
    return history.push("/checkout-success");
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const closePaymentFailureModal = () => {
    setPaymentError("");
    if (checkout.lineItems.length !== 0) {
      dispatch(addValidationCounter()); // Only used to update the checkout on login
    }
    history.push("/basket");
  };
  // -----------------------------------------------------------------------------------------------
  const submitAddressUpsert = async (e: any) => {
    e.preventDefault();
    setIsProcessing(true);
    await asyncTimeout(300);

    const address = {
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      email: accountDetails.email,
      address1: accountDetails.address1,
      address2: accountDetails.address2,
      phone: accountDetails.phone,
      id: accountDetails.id,
      city: accountDetails.city,
      stateCode: accountDetails.stateCode,
      countryCode: accountDetails.countryCode,
      postalCode: accountDetails.postalCode,
      deliveryInstruction: accountDetails.deliveryInstruction,
    };

    const result: any = await addressUpsert({
      variables: {
        shop: shopId,
        email: address.email,
        input: address,
        category: "shipping",
      },
    });
    setShippingAddress({
      ...address,
      id: result?.data?.addressUpsert?.address?.id,
    });
    setBillingAddress({
      ...address,
      id: result?.data?.addressUpsert?.address?.id,
    });
    if (!checkout?.billingAddress) {
      const result: any = await addressUpsert({
        variables: {
          shop: shopId,
          email: address.email,
          input: address,
          category: "billing",
        },
      });
      setBillingAddress({
        ...billingAddress,
        id: result?.data?.addressUpsert?.address?.id,
      });
    }

    if (!user?.email && !result?.errors) {
      setShippingAddress({
        ...address,
        id: result?.data?.addressUpsert?.address?.id,
      });
      setBillingAddress({
        ...address,
        id: result?.data?.addressUpsert?.address?.id,
      });
      setGuestEmailForQuery(address?.email);
      sessionStorage.setItem(
        "shippingAddress",
        JSON.stringify({
          ...address,
          id: result?.data?.addressUpsert?.address?.id,
        }),
      );
    }

    if (result.errors) {
      console.warn("submitAddressUpsert() failed", result.errors);
      let errors = [];
      errors = result?.errors?.map((e: any) => e.message);
      setAddressError(errors);
      setTimeout(() => {
        setAddressError([]);
        setIsProcessing(false);
      }, 5000);
    } else {
      handleNewAddress();
      setIsProcessing(false);
    }
  };
  // -----------------------------------------------------------------------------------------------
  const collapse = () => {
    setIsCollapsed(true);
    setTimeout(() => {
      setShippingAddressVisible(false);
      setBillingAddressVisible(false);
      setPaymentFormVisible(false);
      setShippingAddressesVisible(true);
      setIsCollapsed(false);
    }, 500);
  };

  const handleAddAddress = () => {
    setIsCollapsed(true);
    setTimeout(() => {
      setShippingAddressVisible(false);
      setBillingAddressVisible(false);
      setShippingAddressesVisible(false);
      setPaymentFormVisible(false);
      setAddressFormVisible(true);
      setIsCollapsed(false);
    }, 500);
  };

  const handleSetAddress = (e: any) => {
    // console.log(e);
    if (e && e !== shippingAddress.id) {
      setShippingAddress(shippingAddresses.find((a: any) => a.id === e));
    }
    setIsCollapsed(true);
    setTimeout(() => {
      setShippingAddressVisible(true);
      setBillingAddressVisible(true);
      setPaymentFormVisible(true);
      setShippingAddressesVisible(false);
      setIsCollapsed(false);
    }, 500);
  };

  const handleNewAddress = () => {
    setIsCollapsed(true);
    setTimeout(() => {
      setAddressFormVisible(false);
      setShippingAddressVisible(true);
      setBillingAddressVisible(true);
      setPaymentFormVisible(true);
      setIsCollapsed(false);
    }, 500);
  };

  if (isFinalPaymentRedirect && !isFinalPaymentStatusConfirmed) {
    return <LoadingLogo />;
  }

  // -----------------------------------------------------------------------------------------------
  return (
    <>
      <Col xs={12} style={{ padding: "0" }}>
        <h4 style={{ marginBottom: "2rem" }}>Caskshare checkout</h4>
        {SHOW_TEST_CARD_NUMBER ? (
          <div style={{ color: "#ddd", marginBottom: "1rem" }}>
            Test card number:
            <Form.Control
              type="text"
              value={"4000008260000000"}
              style={{
                width: "200px",
                opacity: "0.3",
                display: "inline",
                marginLeft: "6px",
              }}
            />
          </div>
        ) : null}
        <Row
          className={`${styles.shippingRow} ${
            shippingAddressVisible && styles.noHeight
          }`}
        >
          <div
            className={`${styles.contentWrap} ${
              isCollapsed && styles.collapse
            }`}
          >
            {shippingAddressVisible && (
              <>
                <Col xs={12} className={styles.changeLink}>
                  <h5 style={{ marginTop: ".4rem" }}>Shipping Address</h5>
                  {shippingAddress?.firstName && (
                    <p onClick={collapse}>Change</p>
                  )}
                </Col>
                <Col xs={12} className={`${styles.defaultShipping} `}>
                  {shippingAddress && shippingAddress.firstName ? (
                    <>
                      <div className={styles.defaultAddress}>
                        <div className={styles.name}>
                          <p>
                            {shippingAddress.firstName +
                              " " +
                              shippingAddress.lastName}
                          </p>
                        </div>
                        <div className={styles.firstLine}>
                          <p>
                            {shippingAddress.address1 + ", "}
                            {shippingAddress.address2 &&
                              shippingAddress.address2 + ", "}
                            {shippingAddress.phone &&
                              shippingAddress.phone + ", "}
                          </p>
                        </div>
                        <div className={styles.secondLine}>
                          <p>
                            {shippingAddress.city +
                              ", " +
                              shippingAddress.postalCode}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      onClick={handleAddAddress}
                      className={styles.btnSecondary}
                    >
                      Add new address
                    </Button>
                  )}
                </Col>
                {shippingMessage ? (
                  <Col className={styles.errorMessage}>
                    <MdWarning />
                    <p>{shippingMessage}</p>
                  </Col>
                ) : null}
              </>
            )}
            {shippingAddressesVisible && (
              <>
                <Col xs={12}>
                  <h5>Shipping Address</h5>
                </Col>
                <Col className={`${styles.selectShipping} `}>
                  <div className={styles.addressCards}>
                    {shippingAddresses &&
                      shippingAddresses
                        .sort((a: any, b: any) => {
                          if (a.id > b.id) {
                            return -1;
                          }
                          if (a.id < b.id) {
                            return 1;
                          }
                        })
                        .map((address: any, i: any) => (
                          <div
                            key={address.id}
                            className={`${styles.addressTabWrap} ${
                              activeItem.status === i && styles.activeTab
                            }`}
                            onClick={() => {
                              setActiveItem({ status: i, id: address.id });
                            }}
                          >
                            <div className={styles.content}>
                              <p className={styles.name}>
                                <span>
                                  {address.firstName + " " + address.lastName}
                                </span>
                                {address.address1 + ", "}
                                {address.address2 && address.address2 + ", "}
                                {address.phone && address.phone + ", "}
                                {address.city + ", " + address.postalCode}
                                {address.phone
                                  ? ", " + address.phone
                                  : ", " + address.countryCode}
                              </p>
                            </div>
                          </div>
                        ))}
                  </div>
                  <div className={styles.buttons}>
                    <div className={styles.buttonWrap}>
                      <Button
                        onClick={() => handleSetAddress(activeItem.id)}
                        className={styles.btnPrimary}
                      >
                        Use this address
                      </Button>
                    </div>
                    <div className={styles.newAddress}>
                      <div
                        onClick={handleAddAddress}
                        className={styles.content}
                      >
                        <AiOutlinePlus /> <p>Add a new address</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
            {addressFormVisible && (
              <Col className={`${styles.formWrapper}`}>
                <AddressForm
                  accountDetails={accountDetails}
                  setAccountDetails={setAccountDetails}
                  checkout={true}
                  onSubmit={submitAddressUpsert}
                  isProcessing={isProcessing}
                  setShippingAddressVisible={setShippingAddressVisible}
                  setAddressFormVisible={setAddressFormVisible}
                  addressError={addressError}
                  handleNewAddress={handleNewAddress}
                />
              </Col>
            )}
            {billingAddressVisible && (
              <Col className={`${styles.defaultBilling} `}>
                <h5>Billing Address</h5>
                <Form.Group as={Row} controlId="setBillingSameAsShipping">
                  <Col xs={12}>
                    <Form.Check
                      defaultChecked={sameBillingAndShipping}
                      onChange={
                        sameBillingAndShipping
                          ? () => setSameBillingAndShipping(false)
                          : () => setSameBillingAndShipping(true)
                      }
                      label="Set the Billing Details same as Shipping Details"
                    />
                  </Col>
                </Form.Group>
                {!sameBillingAndShipping && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="First name"
                          disabled={sameBillingAndShipping}
                          value={billingAddress.firstName}
                          onChange={(e) => {
                            setBillingAddress({
                              ...billingAddress,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          value={billingAddress.lastName}
                          required
                          disabled={sameBillingAndShipping}
                          onChange={(e) => {
                            setBillingAddress({
                              ...billingAddress,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={billingAddress.address1}
                        placeholder="Line 1"
                        disabled={sameBillingAndShipping}
                        onChange={(e) => {
                          setBillingAddress({
                            ...billingAddress,
                            address1: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={billingAddress.address2}
                        placeholder="Line 2"
                        disabled={sameBillingAndShipping}
                        onChange={(e) => {
                          setBillingAddress({
                            ...billingAddress,
                            address2: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        type="text"
                        required
                        value={billingAddress.city}
                        placeholder="City"
                        disabled={sameBillingAndShipping}
                        onChange={(e) => {
                          setBillingAddress({
                            ...billingAddress,
                            city: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>

                    {billingAddress.countryCode === "US" ? (
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setBillingAddress({
                              ...billingAddress,
                              stateCode: e.target.value,
                            });
                          }}
                          value={billingAddress.stateCode}
                          as="select"
                          required={billingAddress.countryCode === "US"}
                        >
                          {UsStates &&
                            UsStates.map((e, key) => {
                              return (
                                <option key={key} value={e.Key}>
                                  {e.Value}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>
                    ) : null}

                    <Form.Group style={{ marginBottom: "2rem" }}>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        disabled={sameBillingAndShipping}
                        onChange={(e) => {
                          setBillingAddress({
                            ...billingAddress,
                            countryCode: e.target.value,
                          });
                        }}
                        value={billingAddress.countryCode}
                        as="select"
                        required
                      >
                        {Countries &&
                          Countries.map((e, key) => {
                            return (
                              <option key={key} value={e.Key}>
                                {e.Value}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </>
                )}
              </Col>
            )}
            {paymentFormVisible && (
              <Col className={styles.stripeWrap}>
                {/* Stripe Payment Form */}
                {checkout.amountPayable === 0 ? (
                  <Form.Group
                    style={{ display: "flex" }}
                    className="checkout-pay"
                  >
                    <div className="checkout-total">
                      <p>Total:</p>
                      <p>
                        {formatCurrency(shop.currency, 2).format(
                          checkout.amountPayable,
                        )}
                      </p>
                    </div>
                    <Button
                      disabled={
                        !shippingAddress ||
                        !billingAddress ||
                        isProcessing ||
                        isOversold ||
                        !isShippingValidated
                      }
                      onClick={doZeroCheckout}
                    >
                      {isProcessing ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Pay
                        </>
                      ) : (
                        "Pay"
                      )}
                    </Button>
                  </Form.Group>
                ) : (
                  <>
                    {store.getState().shop.market === "EU" ? (
                      <PaymentMollie
                        paymentAmount={checkout.amountPayable}
                        isLoading={isProcessing}
                        isPaymentEnabled={
                          shippingAddress &&
                          isShippingValidated &&
                          billingAddress &&
                          !isProcessing &&
                          !isOversold
                        }
                        onPaymentSubmitted={(cardToken) => {
                          setMollieCardToken(cardToken);
                        }}
                        onPaymentError={(errorMessаgе) => {
                          console.error("errorMessаgе", errorMessаgе);
                        }}
                      />
                    ) : (
                      <>
                        <Form.Group className="card-element-container pb-1">
                          <CardElement
                            onChange={handleCardDetailsChange}
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#495057",
                                  "::placeholder": {
                                    color: "#6c757d",
                                  },
                                },
                              },
                            }}
                          />
                        </Form.Group>

                        <Form.Group
                          style={{ display: "flex" }}
                          className="checkout-pay"
                        >
                          <div className="checkout-total">
                            <p>Total:</p>
                            <p>
                              {formatCurrency(undefined, 2).format(
                                checkout.amountPayable,
                              )}
                            </p>
                          </div>
                          <Button
                            disabled={
                              !shippingAddress ||
                              !billingAddress ||
                              isProcessing ||
                              isOversold ||
                              !isShippingValidated
                            }
                            onClick={handleSubmit}
                          >
                            {isProcessing ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />{" "}
                                Pay
                              </>
                            ) : (
                              "Pay"
                            )}
                          </Button>
                        </Form.Group>
                      </>
                    )}
                  </>
                )}
                {/* ApplePay and GooglePayments */}
                {paymentRequest?.canMakePayment && isShippingValidated ? (
                  <Form.Group className="payment-request">
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                  </Form.Group>
                ) : (
                  ""
                )}
              </Col>
            )}
          </div>
        </Row>
      </Col>
      <PaymentFailureModal
        message={paymentError}
        doClose={closePaymentFailureModal}
        show={Boolean(paymentError)}
      />
    </>
  );
};

export default Payment;
